import React, { useEffect, useState } from 'react';

interface WaistCircumferenceProps {
  measureData: {
    waistcircum: number;
    createdAt: string;
    PatientId: number;
  };
  gender: 'Male' | 'Female'; // Expect gender as 'Male' or 'Female'
  race: 'Indian' | 'Chinese' | 'Malay' | 'Other'; // Expect race to be provided
}

// Function to determine waist circumference category based on gender, race, and waistcircum
const getWaistCircumCategory = (
  gender: 'Male' | 'Female',
  waistcircum: number,
  race: 'Indian' | 'Chinese' | 'Malay' | 'Other'
): string => {
  if (race === 'Indian' || race === 'Chinese' || race === 'Malay') {
    if (gender === 'Male') {
      return waistcircum < 90 ? 'Normal' : 'High';
    } else if (gender === 'Female') {
      return waistcircum < 80 ? 'Normal' : 'High';
    }
  } else {
    // For all other races
    if (gender === 'Male') {
      return waistcircum < 102 ? 'Normal' : 'High';
    } else if (gender === 'Female') {
      return waistcircum < 88 ? 'Normal' : 'High';
    }
  }
  return 'Unknown';
};

const commonpClassStyles = 'text-sm text-gray-800 h-20';

const WaistCircumference: React.FC<WaistCircumferenceProps> = ({ measureData, gender, race }) => {
  const [waistCircumCategory, setWaistCircumCategory] = useState<string | null>(null);

  useEffect(() => {
    if (measureData && measureData.waistcircum) {
      const category = getWaistCircumCategory(gender, measureData.waistcircum, race);
      setWaistCircumCategory(category);
    }
  }, [measureData, gender, race]);

  if (!measureData || !measureData.waistcircum || waistCircumCategory === null) {
    return null; // Render nothing until data is available
  }

    // Define the content based on body fat value
    let waistcircumContent = (
        <p className={commonpClassStyles}>
          Your waist circumference is <strong>{measureData.waistcircum}</strong>. Based on your
          gender ({gender}) and race ({race}), your waist circumference category is{' '}
          <strong>{waistCircumCategory}</strong>.
        </p>
      );
    
      // Check if measureData is empty
      if (!measureData || Object.keys(measureData).length === 0) {
        return (
          <div className="text-red-600">
            Missing data. Please contact your clinic.
          </div>
        );
      }

  return (
    <div id="waist-circumference" className="flex">
      <div className="w-full">
      {waistcircumContent}
        <div className="w-96 overflow-x-auto">
          <div className="border rounded-lg dark:border-gray-700 overflow-scroll">
            <table className="divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th className="px-3 py-2 sm:px-4 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase">
                    Waist Circumference (cm)
                  </th>
                  <th className="px-3 py-2 sm:px-4 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase">
                    Waist Circumference Category
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="h-14 px-3 py-2 sm:px-4 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200">
                    {measureData.waistcircum}
                  </td>
                  <td
                    className={`h-10 px-3 py-2 sm:px-4 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200
                    ${waistCircumCategory === 'Normal' ? 'text-green-700' : ''}
                    ${waistCircumCategory === 'High' ? 'text-red-700' : ''}
                    `}
                  >
                    {waistCircumCategory === 'Normal' ? (
                      <span className="px-1 py-1 rounded text-xs bg-green-100 text-green-700">
                        {waistCircumCategory}
                      </span>
                    ) : waistCircumCategory === 'High' ? (
                      <span className="px-1 py-1 rounded text-xs bg-red-100 text-red-700">
                        {waistCircumCategory}
                      </span>
                    ) : (
                      <span className="px-1 py-1 rounded text-xs bg-gray-100 text-gray-700">
                        Unknown
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-1/2 p-4"></div>
    </div>
  );
};

export default WaistCircumference;
