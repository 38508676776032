import React, { useEffect, useState } from 'react';

interface VisceralFatProps {
  measureData: {
    visceralfat: number;
    createdAt: string;
    PatientId: number;
  };
}

const commonpClassStyles = 'text-sm text-gray-800 h-20';

// Function to determine the visceral fat category based on visceral fat value
const getVisceralFatCategory = (visceralfat: number): string => {
  if (visceralfat >= 1 && visceralfat <= 9) return 'Normal';
  if (visceralfat >= 10 && visceralfat <= 14) return 'Slightly High';
  if (visceralfat >= 15) return 'High';
  return 'Unknown';
};

const VisceralFat: React.FC<VisceralFatProps> = ({ measureData }) => {
  const [visceralfat, setVisceralFat] = useState<number | null>(null);
  const [visceralFatCategory, setVisceralFatCategory] = useState<string | null>(null);

  useEffect(() => {
    if (measureData) {
      setVisceralFat(measureData.visceralfat);
      // Calculate the visceral fat category based on visceral fat value
      const category = getVisceralFatCategory(measureData.visceralfat);
      setVisceralFatCategory(category);
    }
  }, [measureData]);

  if (visceralfat === null || visceralFatCategory === null) {
    return null; // Render nothing until data is available
  }

  // Define the content based on visceral fat value
  let biometricsContent = (
    <p className={commonpClassStyles}>
      Your visceral fat level is <strong>{visceralfat}</strong>. Based on your visceral fat value, 
      your visceral fat category is <strong>{visceralFatCategory}</strong>.
    </p>
  );

  // Check if measureData is empty
  if (!measureData || Object.keys(measureData).length === 0) {
    return (
      <div className="text-red-600">
        Missing data. Please contact your clinic.
      </div>
    );
  }

  return (
    <div id="visceralfat" className="flex">
      <div className="w-full">
        {biometricsContent}
        <div className="w-96 overflow-x-auto">
          <div className="border rounded-lg dark:border-gray-700 overflow-scroll">
            <table className="divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-4 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Visceral Fat
                  </th>
                  <th
                    scope="col"
                    className="h-14 px-3 py-2 sm:px-4 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Visceral Fat Category
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`h-10 px-3 py-2 sm:px-4 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200
                     ${visceralFatCategory === 'Normal' ? 'text-green-700' : ''}
                     ${visceralFatCategory === 'Slightly High' ? 'text-amber-400' : ''}
                     ${visceralFatCategory === 'High' ? 'text-red-700' : ''}
                     `}
                  >
                    {visceralfat}
                  </td>
                  <td className="px-3 py-2 sm:px-4 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200">
                    {visceralFatCategory === 'Normal' ? (
                      <span className="px-1 py-1 rounded text-xs bg-green-100 text-green-700">
                        {visceralFatCategory}
                      </span>
                    ) : visceralFatCategory === 'Slightly High' ? (
                      <span className="px-1 py-1 rounded text-xs bg-amber-100 text-amber-600">
                        {visceralFatCategory}
                      </span>
                    ) : visceralFatCategory === 'High' ? (
                      <span className="px-1 py-1 rounded text-xs bg-red-100 text-red-700">
                        {visceralFatCategory}
                      </span>
                    ) : (
                      <span className="px-1 py-1 rounded text-xs bg-gray-100 text-gray-700">
                        Unknown
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-1/2 p-4"></div>
    </div>
  );
};

export default VisceralFat;
