import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MilkCarton from '../../images/logo/milk-carton.svg';
import BodyMassIndex from '../../components/healthreport/BodyMassIndex';
import BloodPressure from '../../components/healthreport/BloodPressure';
import ExamFindings from '../../components/healthreport/ExamFindings';
import Investigations from '../../components/healthreport/Investigations';
import TestProcedures from '../../components/healthreport/TestProcedures';
import Footer from '../../components/Footer';
import Recommendation from '../../components/healthreport/Recommendation';
import MedicalHx from '../../components/healthreport/MedicalHx';
import { Spinner } from '@material-tailwind/react';
import calculateAge from '../../function/calculateAge';
import BodyFat from '../../components/healthreport/BodyFat';
import WaistCircumference from '../../components/healthreport/WaistCircumference';
import VisceralFat from '../../components/healthreport/VisceralFat';
import RestingMetabolism from '../../components/healthreport/RestingMetabolism';
import FraminghamSGGraph from '../../components/testFormRenders/FraminghamSGGraph';
import FraminghamUSGraph from '../../components/testFormRenders/FraminghamUSGraph';
import convertUTCToSGTimestamp from '../../function/convertUTCToSGTimestamp';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// styles
const commonTextStyle =
  'py-1 text-sm sm:text-sm leading-5 text-gray-700 dark:text-gray-400';
const commonLinkStyles =
  'py-1 text-sm sm:text-sm leading-5 text-gray-700 hover:text-blue-800 dark:text-gray-400 dark:hover:text-gray-300 hs-scrollspy-active:text-blue-600 dark:hs-scrollspy-active:text-blue-400';
const commonSvgStyles =
  'w-2 h-6 text-gray-400 overflow-visible group-hover:text-gray-600 dark:text-gray-600 dark:group-hover:text-gray-500';
const commonH2Styles =
  'py-0 sm:py-2 text-base sm:text-lg font-semibold text-gray-800 dark:text-white';
const commonH3Styles =
  'py-0 sm:py-2 text-sm sm:text-base font-semibold text-gray-800 dark:text-white';

const commonSvgIcon = (
  <svg
    className={` ${commonSvgStyles}`}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 2L10.6464 7.64645C10.8417 7.84171 10.8417 8.15829 10.6464 8.35355L5 14"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const fetchDataFromAPI = async (url, token) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch data');
  }

  return await response.json();
};

const Healthscreening = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const uuid = params.get('u');
  const patientId = params.get('p');

  // console.log('UUID:', uuid);
  // console.log('Visit Id:', visitId);
  // console.log('Patient Id:', patientId);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Add loading state

  const [patientData, setPatientData] = useState({
    name: '',
    nric: '',
    birthDate: '',
    gender: '',
    race: '',
    daStatus: '',
    daName: '',
    linkActive: '',
    age: '', // Add age property to patientData
    createdAt: '',
  });

  const [visitData, setVisitData] = useState(null);

  const [measureData, setMeasureData] = useState(null); // Add state for measureData
  const [fileData, setFileData] = useState(null); // Add state for measureData
  const [consolidatedFormData, setConsolidatedFormData] = useState({
    standardForms: [],
    specialForms: [],
    framinghamForms: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const sessionDataString = sessionStorage.getItem('sessionToken');
        const token = sessionDataString;

        // console.log("token", token);

        if (!token) {
          console.error('Missing session token.');
          return;
        }

        if (!patientId) {
          console.error('Missing patient ID.');
          return;
        }

        const visitDataResponse = await fetchDataFromAPI(
          `${REACT_APP_API_BASE_URL}/healthreport/visit/${uuid}`,
          token,
        );
        setVisitData(visitDataResponse);

        const patientDetailResponse = await fetchDataFromAPI(
          `${REACT_APP_API_BASE_URL}/healthreport/patientdetails/${patientId}`,
          token,
        );
        setPatientData({
          name: patientDetailResponse.name,
          nric: patientDetailResponse.nric,
          birthDate: patientDetailResponse.birthDate,
          gender: patientDetailResponse.gender,
          race: patientDetailResponse.race,
          daStatus: patientDetailResponse.daStatus,
          daName: patientDetailResponse.daName,
          linkActive: patientDetailResponse.linkActive,
          age: calculateAge(patientDetailResponse.birthDate).toString(),
          createdAt: convertUTCToSGTimestamp(patientDetailResponse.createdAt),
        });

        if (uuid) {
          const formDataURL = `${REACT_APP_API_BASE_URL}/healthreport/forms/uuid/${uuid}`;
          const measureDataURL = `${REACT_APP_API_BASE_URL}/healthreport/measures/uuid/${uuid}`;
          const fileDataURL = `${REACT_APP_API_BASE_URL}/healthreport/files/${patientId}`;

          const formResponse = await fetchDataFromAPI(formDataURL, token);

          // Check formType and handle accordingly
          const specialFormTypes = [
            'Tonometry',
            'Vision',
            'Audiogram',
            'Colonoscopy',
          ];

          const framinghamFormTypes = ['Framingham'];

          if (Array.isArray(formResponse)) {
            const standardForms = [];
            const specialForms = [];
            const framinghamForms = []; // New array for Framingham forms

            formResponse.forEach((formItem) => {
              // First check if the formType is Framingham
              if (framinghamFormTypes.includes(formItem.formType)) {
                framinghamForms.push(formItem); // Add to Framingham forms array
              } else if (specialFormTypes.includes(formItem.formType)) {
                specialForms.push(formItem);
              } else {
                standardForms.push(formItem);
              }
            });

            // console.log("Standard Forms:", standardForms); // Debugging line
            // console.log("Special Forms:", specialForms); // Debugging line
            // console.log("Framingham Forms:", framinghamForms); // Debugging line

            setConsolidatedFormData({
              standardForms,
              specialForms,
              framinghamForms,
            }); // Include Framingham forms
          } else {
            // Handle single formResponse case
            if (specialFormTypes.includes(formResponse.formType)) {
              setConsolidatedFormData((prevData) => ({
                ...prevData,
                specialForms: [...prevData.specialForms, formResponse],
              }));
            } else if (framinghamForms.includes(formResponse.formType)) {
              setConsolidatedFormData((prevData) => ({
                ...prevData,
                framinghamForms: [...prevData.framinghamForms, formResponse], // Add to Framingham forms
              }));
            } else {
              setConsolidatedFormData((prevData) => ({
                ...prevData,
                standardForms: [...prevData.standardForms, formResponse],
              }));
            }
          }

          // console.log("Consolidated Form Data:", consolidatedFormData);
          // console.log("Consolidated Form Special", consolidatedFormData.specialForms )

          // console.log("Consolidated Form Special Length", consolidatedFormData.specialForms.length )
          const measureResponse = await fetchDataFromAPI(measureDataURL, token);
          setMeasureData(measureResponse);

          const fileResponse = await fetchDataFromAPI(fileDataURL, token);
          setFileData(fileResponse);
          // console.log("File Data:", fileResponse);
        } else {
          console.error('Invalid or missing UUID.');
        }

        if (!visitDataResponse.linkActive) {
          navigate('/inactivelink');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uuid, patientId, navigate]);

  // Function to handle sidebar click
  const handleSidebarClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute('href').substring(1);
    const currentUrl = `${location.pathname}${location.search}`;

    // Remove existing hash fragments
    const urlWithoutHash = currentUrl.split('#')[0];

    // Construct the new URL with the targetId appended
    const newUrl = `${urlWithoutHash}#${targetId}`;

    // Update the URL without reloading the page
    window.history.pushState(null, '', newUrl);

    // Scroll to the target element
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      {/* Conditional rendering of spinner based on loading state */}
      {loading ? (
        <div className="text-center">
          <div role="status">
            <Spinner className="h-12 w-12" />
          </div>
        </div>
      ) : (
        <div className="px-4 py-4 sm:px-10 sm:py-10 lg:px-10 lg:py-10 lg:mx-10 sm:mx-2">
          <div className="grid grid-cols-1 sm:grid-cols-5">
            {/*  Start of Side Navigation */}
            <div className="col-span-1 hidden sm:block">
              <div className="pt-4 pb-2 sm:pt-10 sm:pb-6">
                <img
                  src={MilkCarton}
                  alt="Logo"
                  className="inline w-20 h-auto"
                />
              </div>
              <h2 className="text-xl font-medium text-gray-800 dark:text-white">
                Contents
              </h2>

              <ul
                className="sticky top-0"
                data-hs-scrollspy="#scrollspy-2"
                data-hs-scrollspy-scrollable-parent="#scrollspy-scrollable-parent-2"
              >
                <li data-hs-scrollspy-group>
                  <a
                    href="#recs"
                    onClick={handleSidebarClick}
                    className={`block ${commonLinkStyles} active`}
                  >
                    Recommendations
                  </a>
                </li>
                {consolidatedFormData.framinghamForms &&
                  consolidatedFormData.framinghamForms.length > 0 && (
                    <li className="ml-4">
                      <a
                        href="#risks"
                        onClick={handleSidebarClick}
                        className={`group flex items-start gap-x-2 ${commonLinkStyles}`}
                      >
                        {commonSvgIcon}
                        Cardiovascular Risk Score
                      </a>
                    </li>
                  )}
                <li data-hs-scrollspy-group>
                  <a
                    href="#pmhx"
                    onClick={handleSidebarClick}
                    className={`block ${commonLinkStyles} active`}
                  >
                    Medical History
                  </a>
                </li>
                <li>
                  <a
                    href="#anthropometricmeasurements"
                    onClick={handleSidebarClick}
                    className={`block ${commonLinkStyles}`}
                  >
                    Anthropometric Measurements
                  </a>
                </li>
                <li>
                  <a
                    href="#vitals"
                    onClick={handleSidebarClick}
                    className={`block ${commonLinkStyles}`}
                  >
                    Vitals
                  </a>
                </li>
                <li data-hs-scrollspy-group>
                  <a
                    href="#bodysystems"
                    onClick={handleSidebarClick}
                    className={`block ${commonLinkStyles}`}
                  >
                    Body Systems
                  </a>
                  <ul>
                    <li className="ml-4">
                      <a
                        href="#physicalexam"
                        onClick={handleSidebarClick}
                        className={`group flex items-start gap-x-2 ${commonLinkStyles}`}
                      >
                        {commonSvgIcon}
                        Physical Exam
                      </a>
                    </li>
                    {consolidatedFormData.specialForms &&
                      consolidatedFormData.specialForms.length > 0 && (
                        <li className="ml-4">
                          <a
                            href="#testprocedures"
                            onClick={handleSidebarClick}
                            className={`group flex items-start gap-x-2 ${commonLinkStyles}`}
                          >
                            {commonSvgIcon}
                            Tests & Procedures
                          </a>
                        </li>
                      )}
                    {fileData.files && fileData.files.length > 0 && (
                      <li className="ml-4">
                        <a
                          href="#investigations"
                          onClick={handleSidebarClick}
                          className={`group flex items-start gap-x-2 ${commonLinkStyles}`}
                        >
                          {commonSvgIcon}
                          Investigations
                        </a>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </div>
            {/*  End of Side Navigation */}

            {/*  Start of Health Report */}
            <div className="col-span-1 sm:col-span-4 bg-white py-4 px-4 sm:py-6 sm:px-10 lg:py-8 lg:px-12 ">
              {/*  Header */}
              <div className="pb-10 text-end">
                <p
                  className={`inline items-start pt-20 font-medium ${commonTextStyle}`}
                >
                  Visit Date:
                </p>
                <p
                  className={`pl-2 inline items-start gap-x-2 ${commonTextStyle}`}
                >
                  {patientData.createdAt}
                </p>
              </div>

              <div className="flex-row sm:flex justify-between space-y-4 sm:space-y-0">
                <h1 className="text-title-sm sm:text-title-md2 font-semibold text-black dark:text-white">
                  {patientData.name} {patientData.nric}
                </h1>
                <div className="flex-row sm:flex rounded-md">
                  <p className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400">
                    {patientData.age} years old
                  </p>
                  <p className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400">
                    {patientData.race}
                  </p>
                  <p className="py-3 px-4 inline-flex justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400">
                    {patientData.gender}
                  </p>
                </div>
              </div>
              {/*  DA */}
              <div className="pt-4 pb-4 sm:pt-0 sm:pb-10">
                {patientData.daStatus === 'NKDA' ? (
                  // Render "No Known Drug Allergy" when daStatus is "NKDA"
                  <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                    No known drug allergy
                  </span>
                ) : (
                  <div>
                    <span className="bg-fuchsia-100 text-fuchsia-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-fuchsia-900 dark:text-fuchsia-300">
                      Drug allergy: {patientData.daName}
                    </span>
                  </div>
                )}
              </div>

              <div id="scrollspy-2" className="space-y-4 sm:space-y-6">
                {/*  Recommendations */}
                <div id="recs">
                  <h2 className={commonH2Styles}>Recommendations</h2>
                  <Recommendation
                    formData={consolidatedFormData.standardForms}
                  />
                </div>
                {/*  Risks */}
                <div id="risks">
                  <h2 className={commonH2Styles}>Cardiovascular Risk Score</h2>
                  {/* Conditionally render based on race */}
                  {consolidatedFormData.framinghamForms.length > 0 &&
                    (['Indian', 'Malay', 'Chinese'].includes(
                      patientData.race,
                    ) ? (
                      <FraminghamSGGraph
                        race={patientData.race}
                        gender={patientData.gender}
                        framinghamForms={consolidatedFormData.framinghamForms}
                      />
                    ) : (
                      <FraminghamUSGraph
                        race={patientData.race}
                        gender={patientData.gender}
                        framinghamForms={consolidatedFormData.framinghamForms}
                      />
                    ))}
                </div>
                {/*  Medical Hx */}
                <div id="pmhx">
                  <h2 className={commonH2Styles}>Medical History</h2>
                  <MedicalHx formData={consolidatedFormData.standardForms} />
                </div>

                {/* Anthropometric Measurements */}
                <div
                  id="anthropometricmeasurements"
                  className="space-y-4 sm:space-y-4"
                >
                  <h2 className={commonH2Styles}>
                    Anthropometric Measurements
                  </h2>

                  {/* Biometrics - BMI */}
                  <div id="bmi">
                    <h3 className={commonH3Styles}>Body Mass Index (BMI)</h3>
                    <BodyMassIndex measureData={measureData} />
                  </div>

                  {/* Body Fat and Waist Circumference in the same row */}
                  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                    {/* Body Fat */}
                    <div id="bodyfat" className="w-full sm:w-full">
                      <h3 className={commonH3Styles}>Body Fat (%)</h3>
                      <BodyFat
                        measureData={measureData}
                        gender={patientData.gender}
                      />
                    </div>

                    {/* Waist Circumference */}
                    <div id="waistcircum" className="w-full sm:w-full">
                      <h3 className={commonH3Styles}>
                        Waist Circumference (cm)
                      </h3>
                      <WaistCircumference
                        measureData={measureData}
                        gender={patientData.gender}
                        race={patientData.race}
                      />
                    </div>
                  </div>

                  {/* Visceral Fat and Resting Metabolism in the same row */}
                  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
                    {/* Visceral Fat */}
                    <div id="visceralfat" className="w-full sm:w-full">
                      <h3 className={commonH3Styles}>Visceral Fat Score</h3>
                      <VisceralFat measureData={measureData} />
                    </div>

                    {/* Resting Metabolism */}
                    <div id="restingmetabolism" className="w-full sm:w-full">
                      <h3 className={commonH3Styles}>
                        Resting Metabolism (Kcals)
                      </h3>
                      <RestingMetabolism
                        measureData={measureData}
                        gender={patientData.gender}
                      />
                    </div>
                  </div>
                </div>

                {/*  Vitals */}
                <div id="vitals" className="space-y-4 sm:space-y-0">
                  <h2 className={commonH2Styles}>Vitals</h2>
                  {/*  Biometrics - BMI*/}
                  <div id="bp">
                    <h3 className={commonH3Styles}>Blood Pressure (BP)</h3>
                    <BloodPressure measureData={measureData} />
                  </div>
                </div>

                {/*  Body Systems */}
                <div id="bodysystems" className="space-y-4 sm:space-y-4">
                  <h2 className={commonH2Styles}>Body Systems</h2>
                  {/*  Body Systems - Physical Exam */}
                  <div id="physicalexam">
                    <h3 className={commonH3Styles}>Physical Exam</h3>
                    <ExamFindings
                      formData={consolidatedFormData.standardForms}
                    />
                  </div>
                  {consolidatedFormData.specialForms &&
                    consolidatedFormData.specialForms.length > 0 && (
                      <div id="testprocedures">
                        <h3 className={commonH3Styles}>Tests & Procedures</h3>
                        <TestProcedures
                          formData={consolidatedFormData.specialForms}
                        />
                      </div>
                    )}
                  {fileData.files && fileData.files.length > 0 && (
                    <div
                      id="investigations"
                      className="pt-6 flex flex-col sm:flex-row gap-6"
                    >
                      <div className="flex-1 flex-grow sm:overflow-x-auto">
                        <h3 className={`${commonH3Styles} pb-4`}>
                          Investigations
                        </h3>
                        <div className="overflow-x-auto">
                          <Investigations
                            patientId={patientId}
                            files={fileData.files}
                          />
                        </div>
                      </div>

                      <div className="flex-1 flex-grow">
                        {/* Keep this empty for now */}
                      </div>
                    </div>
                  )}
                </div>
                {/*  End ofBody Systems */}
              </div>
            </div>
            {/*  End of Health Report */}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Healthscreening;
