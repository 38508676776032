import React, { useEffect, useState } from 'react';

interface RestingMetabolismProps {
  measureData: {
    restingmetabolism: number;
    createdAt: string;
    PatientId: number;
  };
  gender: 'Male' | 'Female'; // Expect gender as 'Male' or 'Female'
}

const commonpClassStyles = 'text-sm text-gray-800 h-20';

// Function to determine the resting metabolism category based on gender and value
const getRestingMetabolismCategory = (
  gender: 'Male' | 'Female',
  restingmetabolism: number,
): string => {
  if (gender === 'Male') {
    if (restingmetabolism <= 1700) return 'Normal';
    if (restingmetabolism > 1700) return 'High';
  } else if (gender === 'Female') {
    if (restingmetabolism <= 1400) return 'Normal';
    if (restingmetabolism > 1400) return 'High';
  }
  return 'Unknown';
};

const RestingMetabolism: React.FC<RestingMetabolismProps> = ({ measureData, gender }) => {
  const [restingmetabolism, setRestingMetabolism] = useState<number | null>(null);
  const [metabolismCategory, setMetabolismCategory] = useState<string | null>(null);

  useEffect(() => {
    if (measureData) {
      setRestingMetabolism(measureData.restingmetabolism);
      // Calculate the resting metabolism category based on gender
      const category = getRestingMetabolismCategory(gender, measureData.restingmetabolism);
      setMetabolismCategory(category);
    }
  }, [measureData, gender]);

  if (restingmetabolism === null || metabolismCategory === null) {
    return null; // Render nothing until data is available
  }

  // Define the content based on resting metabolism value
  let biometricsContent = (
    <p className={commonpClassStyles}>
      Your resting metabolism is <strong>{restingmetabolism} kcal</strong>. Based on your gender ({gender}),
      your resting metabolism category is <strong>{metabolismCategory}</strong>.
    </p>
  );

  // Check if measureData is empty
  if (!measureData || Object.keys(measureData).length === 0) {
    return (
      <div className="text-red-600">
        Missing data. Please contact your clinic.
      </div>
    );
  }

  return (
    <div id="restingmetabolism" className="flex">
      <div className="w-full">
        {biometricsContent}
        <div className="w-96 overflow-x-auto">
          <div className="border rounded-lg dark:border-gray-700 overflow-scroll">
            <table className="divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-4 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Resting Metabolism (kcal)
                  </th>
                  <th
                    scope="col"
                    className="h-14 px-3 py-2 sm:px-4 sm:py-3 w-1/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Metabolism Category
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`h-10 px-3 py-2 sm:px-4 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200
                     ${metabolismCategory === 'Normal' ? 'text-green-700' : ''}
                     ${metabolismCategory === 'High' ? 'text-red-700' : ''}
                     `}
                  >
                    {restingmetabolism}
                  </td>
                  <td className="px-3 py-2 sm:px-4 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200">
                    {metabolismCategory === 'Normal' ? (
                      <span className="px-1 py-1 rounded text-xs bg-green-100 text-green-700">
                        {metabolismCategory}
                      </span>
                    ) : metabolismCategory === 'High' ? (
                      <span className="px-1 py-1 rounded text-xs bg-red-100 text-red-700">
                        {metabolismCategory}
                      </span>
                    ) : (
                      <span className="px-1 py-1 rounded text-xs bg-gray-100 text-gray-700">
                        Unknown
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-1/2 p-4"></div>
    </div>
  );
};

export default RestingMetabolism;
