import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';

interface ChartAudiogramProps {
  leftEarData: { [key: string]: number | string };
  rightEarData: { [key: string]: number | string };
}

const ChartAudiogram: React.FC<ChartAudiogramProps> = ({ leftEarData, rightEarData }) => {
  // Prepare data for the chart
  const data = [
    [
      'Frequency (Hz)',
      '',
      'Profound Hearing Loss',
      'Severe Hearing Loss',
      'Moderate Severe Hearing Loss',
      'Moderate Hearing Loss',
      'Mild Hearing Loss',
      'Normal',
      'Left Ear',
      'Right Ear',
    ],
    ['250', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[250]), Number(rightEarData[250])],
    ['500', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[500]), Number(rightEarData[500])],
    ['1000', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[1000]), Number(rightEarData[1000])],
    ['2000', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[2000]), Number(rightEarData[2000])],
    ['4000', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[4000]), Number(rightEarData[4000])],
    ['6000', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[6000]), Number(rightEarData[6000])],
    ['8000', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[8000]), Number(rightEarData[8000])],
    ['12000', 120, 91, 71, 56, 41, 20, 0, Number(leftEarData[12000]), Number(rightEarData[12000])],
  ];

  // Set chart options with dynamic colors based on the dark mode state
  const options = {
    chartArea:{width:"80%",height:"80%"},
    hAxis: {
      title: 'Frequency (Hz)',
      minValue: 250,
      maxValue: 12000,
    },
    vAxis: {
      title: 'Hearing Level (dB)',
      minValue: 10,
      maxValue: 120,
      direction: -1, // Inverse the Y-axis
    },
    legend: { position: 'bottom' },
    pointSize: 5,
    series: {
      0: { type: 'area', color: '#A0B3E6', visibleInLegend: false, pointsize: 0 },
      1: { type: 'area', color: '#DAB6EF', visibleInLegend: false, lineWidth: 0 },
      2: { type: 'area', color: '#F2C8E0', visibleInLegend: false, lineWidth: 0 },
      3: { type: 'area', color: '#F9E5B8', visibleInLegend: false, lineWidth: 0 },
      4: { type: 'area', color: '#F9EBB2', visibleInLegend: false, lineWidth: 0 },
      5: { type: 'area', color: '#B4E1D3', visibleInLegend: false, lineWidth: 0 },
      6: { type: 'area', color: '#B4E1D3', visibleInLegend: false, lineWidth: 0 },
      7: { color: '#3C50E0', lineWidth: 3, pointShape: 'circle' },
      8: { color: '#84cc16', lineWidth: 3, pointShape: 'star' },
    },
    backgroundColor: { fill:'transparent' }
  };

  return (
    <div className="py-6">
          <h3 className="text-xl font-semibold text-black dark:text-white">
            Audiogram
          </h3>
      <Chart
        chartType="ComboChart"
        data={data}
        options={options}
      />
      {/* Vertical Legend for Hearing Loss Types */}
      <div className="pl-24 pt-10 pb-4 flex flex-col items-start ml-4 text-base dark:bg-boxdark">
        <h4 className="font-semibold text-black dark:text-white">Legend</h4>
        <div className="flex items-center mt-2">
          <div className="w-4 h-4" style={{ backgroundColor: '#B4E1D3' }}></div>
          <span className="ml-2">Normal Hearing (Below 20 dB)</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4" style={{ backgroundColor: '#F9EBB2' }}></div>
          <span className="ml-2">Mild Hearing Loss (20-40 dB)</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4" style={{ backgroundColor: '#F9E5B8' }}></div>
          <span className="ml-2">Moderate Hearing Loss (41-55 dB)</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4" style={{ backgroundColor: '#F2C8E0' }}></div>
          <span className="ml-2">Moderate Severe Hearing Loss (56-70 dB)</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4" style={{ backgroundColor: '#DAB6EF' }}></div>
          <span className="ml-2">Severe Hearing Loss (71-90 dB)</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4" style={{ backgroundColor: '#A0B3E6' }}></div>
          <span className="ml-2">Profound Hearing Loss (greater than 90 dB)</span>
        </div>
      </div>
    </div>
  );
};

export default ChartAudiogram;
