import { useEffect, useState } from 'react';
import axios from 'axios';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

interface TemplateContent {
  Checklist?: CheckListCategory[];
  // Add other potential properties
}

interface Task {
  itemName: string;
  status: string;
}

interface CheckListCategory {
  category: string;
  tasks: Task[];
}

const useTemplate = () => {
  const [templateData, setTemplateData] = useState<TemplateContent>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${REACT_APP_API_BASE_URL}/templates`, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const organizedData: TemplateContent = {};
        response.data.forEach((template: { templateType: string; templateContent: any }) => {
          const { templateType, templateContent } = template;
          organizedData[templateType] = templateContent;
        });

        setTemplateData(organizedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { templateData, loading };
};

export default useTemplate;
