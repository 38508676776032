import React, { useState, useEffect } from 'react';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import LogRocket from 'logrocket';

import AppLanding from './pages/AppLanding';
import SignIn from './pages/Authentication/SignIn';
import SignUp from './pages/Authentication/SignUp';

import CreateClinic from './pages/Onboarding/CreateClinic';

import ActualizeVisit from './pages/Patient/ActualizeVisit';

import Error404 from './pages/404';
import Error500 from './pages/500';

import HeartFailureQuestionnaire from './pages/Questionnaires/HeartFailureQuestionnaire';
import Questionnaire from './pages/Questionnaires/Questionnaire';
import DevelopmentalAssessment from './pages/Questionnaires/DevelopmentalAssessment';

import HealthReport from './pages/HealthReport/HealthReport';
import InactiveLink from './pages/HealthReport/InactiveLink';
import PasswordCheck from './pages/HealthReport/PasswordCheck';

import Loader from './common/Loader';
import routes from './routes';
import PatientList from './pages/PatientList';
import Patient from './pages/Patient/Patient';
import ClinicSelection from './pages/Authentication/ClinicSelection';
import useUserDetails from './hooks/useUserDetails';

const DefaultLayout = lazy(() => import('./layout/DefaultLayout'));

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const { userDetails, clinicDetails } = useUserDetails(); // Fetch user details with custom hook

  useEffect(() => {
    // Only run LogRocket in production
    if (process.env.NODE_ENV === 'production' && userDetails && clinicDetails) {
      // LogRocket.identify is called after user details and clinic details are available
      LogRocket.identify(userDetails.id, {
        name: userDetails.name,
        email: userDetails.email,
        roleType: userDetails.role,
        clinicName: clinicDetails.clinicName,
        clinicId: clinicDetails.clinicId,
      });
    }
  }, [userDetails, clinicDetails]); // Make sure to include clinicDetails in the dependencies

  return loading ? (
    <Loader />
  ) : (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        containerClassName="overflow-auto"
      />
      <Routes>
        <Route path="/" element={<AppLanding />} />
        <Route path="/auth/signin" element={<SignIn />} />
        <Route path="/auth/signup" element={<SignUp />} />
        <Route path="/user" element={<ClinicSelection />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route path="/inactivelink" element={<InactiveLink />} />
        <Route path="/passwordcheck/:uuid" element={<PasswordCheck />} />
        <Route path="/createclinic" element={<CreateClinic />} />
        <Route
          path="/clinic/:clinicId/actualize/:visitId"
          element={<ActualizeVisit />}
        />

        <Route
          path="/clinic/:clinicId/heartfailure"
          element={<HeartFailureQuestionnaire />}
        />
        <Route path="/clinic/:clinicId/Q/:uuid?" element={<Questionnaire />} />
        <Route
          path="/clinic/:clinicId/devassessment"
          element={<DevelopmentalAssessment />}
        />

        <Route element={<DefaultLayout />}>
          <Route path="/list" element={<PatientList />} />
          {routes.map(({ path, component: Component }) => (
            <Route
              key={path} // Add unique key using route path
              path={path}
              element={
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Route>
        {/* Add the route for Patient and pass the nric parameter */}
        <Route path="/patient/:enroled_id" element={<Patient />} />
        <Route path="/report" element={<HealthReport />} />
      </Routes>
    </>
  );
}

export default App;
