import React, { useCallback, memo, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import cover from '../../images/cover/cover-geometric.svg';
import SuccessAlert from '../../components/alerts/SuccessAlert';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import FormAdditionalMedHx from '../../components/form/FormAdditionalMedHx';
import FormAdditionalFMHx from '../../components/form/FormAdditionalFmHx';
import { useParams } from 'react-router-dom';
import convertUTCToSGTimestamp from '../../function/convertUTCToSGTimestamp.js';

interface RowOfLabelsProps {
  chosenFamilyMembers: string[]; // Adjust the type as necessary
}

interface DiseaseListItemProps {
  disease: string; // The disease name
  familyMembers: string[]; // List of family member names
  memberDiseasesMap: Record<string, string[]>; // A map of family members to their diseases
  handleFamilyCheckboxChange: (disease: string, member: string) => void; // Function to handle checkbox changes
}

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const titleStyle = 'text-xl text-gray-900';
const textStyleStrong = 'text-sm font-medium text-gray-900 dark:text-white';
const textStyle = 'text-sm text-gray-900 dark:text-white';
const requiredAsteriskStyle = 'text-red-500 mx-1';
const inputCheckboxStyle =
  'rounded-full appearance-none mr-4 inline-block w-4 bg-white border border-gray-300 text-gray-900 text-base rounded focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500';

const Questionnaire: React.FC = () => {
  const [formData, setFormData] = useState<any>({});
  const [formTemplate, setFormTemplate] = useState<any>(null);
  const [medicalHistory, setMedicalHistory] = useState<any[]>([]);
  const [lifestyleHistory, setLifestyleHistory] = useState<any[]>([]);
  const [diseaseOptions, setDiseaseOptions] = useState<string[]>([]);
  const [familyMembersFull, setFamilyMembersFull] = useState<string[]>([]);
  const [familyMembersShort, setFamilyMembersShort] = useState<string[]>([]);
  const [chosenFamilyMembers, setChosenFamilyMembers] = useState<string[]>([]);
  const [memberDiseasesMap, setMemberDiseasesMap] = useState<any>({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedConditions, setSelectedConditions] = useState<string[]>([]);
  const [additionalMedHx, setAdditionalMedHx] = useState('');
  const [additionalFmHx, setAdditionalFmHx] = useState('');
  const [errorBoxHighlight, setErrorBoxHighlight] = useState<any>({});
  const [formErrorMessages, setFormErrorMessages] = useState<{
    [key: string]: string;
  }>({});
  const [loading, setLoading] = useState(true);

  const inputSelectorStyle = (fieldName: string) => {
    // Determine border color based on errorBoxHighlight state
    const borderColor = errorBoxHighlight[fieldName]
      ? 'border-red-500'
      : 'border-gray-300';

    // Return the complete style string
    return `
          bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5
          dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500
          ${borderColor}
        `;
  };
  const { clinicId, uuid } = useParams(); // Extracting parameters from the URL

  const [patientDetails, setPatientDetails] = useState<any>({});

  // Fetch patient details when component mounts or `uuid` changes
  useEffect(() => {
    setLoading(true); // Start loading
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/patient/visit/details/uuid/${uuid}`,
      )
      .then((response) => {
        const fetchedPatientDetails = response.data.patientData;

        // Convert the birthDate field
        const formattedBirthDate = convertUTCToSGTimestamp(
          fetchedPatientDetails.birthDate,
        );

        // Set patient details with formatted birthdate
        setPatientDetails({
          ...fetchedPatientDetails,
          birthDate: formattedBirthDate,
        });

        // Update formData with formatted birthdate
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...fetchedPatientDetails,
          birthDate: formattedBirthDate,
        }));
      })
      .catch((error) => {
        console.error('Error fetching patient details:', error);
      });
  }, [uuid]); // Trigger on change of uuid

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_BASE_URL}/patient/template/${clinicId}`,
        );
        const { questionnaires } = response.data;

        const templateQ = questionnaires.find(
          (template: any) => template.templateType === 'Q',
        );
        const templatePMHx = questionnaires.find(
          (template: any) => template.templateType === 'PMHx',
        );
        const templateFMHx = questionnaires.find(
          (template: any) => template.templateType === 'FMHx',
        );
        const templateLifestyle = questionnaires.find(
          (template: any) => template.templateType === 'Lifestyle',
        );

        if (templateQ && templatePMHx && templateFMHx) {
          setFormTemplate(templateQ.templateContent);
          // console.log('formTemplate', templateQ.templateContent);
          initializeFormData(templateQ.templateContent);
          setMedicalHistory(templatePMHx.templateContent.medicalHistory);
          setLifestyleHistory(templateLifestyle.templateContent);
          // console.log("lifestyleHistory",lifestyleHistory);
          setDiseaseOptions(templateFMHx.templateContent.diseaseOptions);
          setFamilyMembersFull(templateFMHx.templateContent.familyMembersFull);
          setFamilyMembersShort(
            templateFMHx.templateContent.familyMembersShort,
          );
        } else {
          console.warn('One or more templates not found.');
        }
      } catch (error) {
        console.error('Error fetching form template:', error);
        setShowErrorAlert(true);
      }
    };
    fetchTemplateData();
  }, []);

  useEffect(() => {
    const updateChosenFamilyMembers = () => {
      const newChosenFamilyMembers =
        window.innerWidth >= 768 ? familyMembersFull : familyMembersShort;
      setChosenFamilyMembers(newChosenFamilyMembers);
    };

    updateChosenFamilyMembers();
    window.addEventListener('resize', updateChosenFamilyMembers);

    return () =>
      window.removeEventListener('resize', updateChosenFamilyMembers);
  }, [familyMembersFull, familyMembersShort]);

  const initializeFormData = (templateContent: any) => {
    if (
      !templateContent ||
      !templateContent.sections ||
      templateContent.sections.length === 0
    ) {
      console.error('No sections found in form template data');
      return;
    }

    const initialData: any = {};
    templateContent.sections.forEach((section) => {
      if (section.fields) {
        section.fields.forEach((field) => {
          initialData[field.name] = ''; // Initialize each field with an empty string
        });
      }
    });

    setFormData(initialData);
    setLoading(false); // End loading
  };

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, useLifestyleHistory: boolean) => {
      const { name, value } = e.target;

      setFormData((prevFormData) => {
        const updatedFormData = { ...prevFormData, [name]: value };
        const updatedErrorMessages = { ...formErrorMessages, [name]: '' };
        const updatedErrorBoxHighlight = {
          ...errorBoxHighlight,
          [name]: false,
        };

        const dataSource = useLifestyleHistory
          ? lifestyleHistory
          : formTemplate?.sections;
        const field = dataSource
          ?.flatMap((section) => section.fields)
          .find((field) => field.name === name);

        if (field?.validation) {
          const { pattern, message } = field.validation;
          const regex = new RegExp(pattern);
          if (!regex.test(value)) {
            updatedErrorMessages[name] = message;
            updatedErrorBoxHighlight[name] = true;
          }
        }

        setFormErrorMessages(updatedErrorMessages);
        setErrorBoxHighlight(updatedErrorBoxHighlight);
        return updatedFormData;
      });
    },
    [formTemplate, lifestyleHistory, formErrorMessages, errorBoxHighlight],
  );

  const handleConditionSelection = (condition: string) => {
    setSelectedConditions((prevSelected) =>
      prevSelected.includes(condition)
        ? prevSelected.filter((item) => item !== condition)
        : [...prevSelected, condition],
    );
  };

  const handleFamilyCheckboxChange = useCallback(
    (disease, member) => {
      const updatedMap = { ...memberDiseasesMap };
      if (!updatedMap[member]) {
        updatedMap[member] = [];
      }
      if (updatedMap[member].includes(disease)) {
        updatedMap[member] = updatedMap[member].filter(
          (d: string) => d !== disease,
        );
      } else {
        updatedMap[member].push(disease);
      }
      setMemberDiseasesMap(updatedMap);
    },
    [memberDiseasesMap],
  );

  // For Medical History
  const handleAdditionalMedHxChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setAdditionalMedHx(e.target.value);
    },
    [],
  );

  // For Family History
  const handleAdditionalFmHxChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setAdditionalFmHx(e.target.value);
    },
    [],
  );

  // Medical History
  const selectedDiseases = medicalHistory.flatMap((section) =>
    section.diseases.filter((condition) =>
      selectedConditions.includes(condition),
    ),
  );

  const selectedDiseasesNoParentheses = selectedDiseases.map((disease) =>
    disease.replace(/\([^()]*\)/g, ''),
  );

  // Add the user-generated "Other" section if there is content in additionalMedHx
  if (additionalMedHx !== '') {
    selectedDiseasesNoParentheses.push(additionalMedHx); // Add as string, not an object
    // console.log('additionalMedHx', additionalMedHx);
  }

  // Other parts of your component/function

  const formattedData = chosenFamilyMembers.map((member) => {
    const selectedDiseasesForMember = memberDiseasesMap[member] || [];
    const diseasesWithoutParentheses = selectedDiseasesForMember.map(
      (disease) => disease.replace(/\s*\(.*?\)\s*/, ''),
    );

    return selectedDiseasesForMember.length > 0
      ? {
          familyMember: member,
          diseases: diseasesWithoutParentheses.join(', '),
        }
      : null;
  });

  // Add the user-generated "Other" section if there is content
  if (additionalFmHx !== '') {
    formattedData.push({
      familyMember: 'Other',
      diseases: additionalFmHx,
    });
    // console.log("otherContent", additionalFmHx);
  }

  const familyHx = formattedData.filter((data) => data !== null);

  const extractRequiredFields = (sections: any[]) => {
    const requiredFields: Set<string> = new Set();

    sections.forEach((section: any) => {
      section.fields.forEach((field: any) => {
        if (field.required) {
          requiredFields.add(field.name);
        }
      });
    });

    return Array.from(requiredFields);
  };

  const validateMissingFields = (formData: any, sections: any[]) => {
    const requiredFields = extractRequiredFields(sections);
    const missingFields: string[] = [];

    requiredFields.forEach((fieldName) => {
      if (formData[fieldName] === undefined || formData[fieldName] === '') {
        missingFields.push(fieldName);
      }
    });

    return missingFields;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Extract sections from both formTemplate and lifestyleHistory
    const formTemplateSections = formTemplate ? formTemplate.sections : [];
    const lifestyleHistorySections = lifestyleHistory
      ? lifestyleHistory.sections
      : [];

    // Combine sections from both sources
    const allSections = [...formTemplateSections, ...lifestyleHistorySections];

    // Validate missing fields
    const missingFields = validateMissingFields(formData, allSections);

    // Log missing fields or handle them as needed
    // console.log('Missing Fields:', missingFields);

    if (missingFields.length > 0) {
      // Create an error object for missing fields
      const errorObject: Record<string, string> = {};

      // Create a mapping of field names to labels
      const fieldLabels: Record<string, string> = {};
      allSections.forEach((section: any) => {
        section.fields.forEach((field: any) => {
          if (field.required) {
            fieldLabels[field.name] = field.label;
          }
        });
      });

      // Populate errorObject with missing fields and their labels
      missingFields.forEach((fieldName) => {
        errorObject[fieldName] = `${fieldLabels[fieldName]} is required.`;
      });

      // Create a readable error message for display
      const missingFieldLabels = missingFields.map(
        (fieldName) => fieldLabels[fieldName],
      );
      const errorMessage = `Fields Missing: ${missingFieldLabels.join(', ')}`;

      // Set error message and show error alert
      setErrorMessage(errorMessage);
      setShowErrorAlert(true);

      // Populate errorObject with missing fields and their labels
      missingFields.forEach((fieldName) => {
        errorObject[fieldName] = `${fieldLabels[fieldName]} is required.`;
      });

      // Highlight the input fields with missing data
      const highlightState = {};
      missingFields.forEach((field) => {
        highlightState[field.name] = true;
      });
      setErrorBoxHighlight({ ...errorBoxHighlight, ...highlightState });
      // console.log('errorBoxHighlight', errorBoxHighlight);

      return;
    }

    try {
      // Get the current URL path
      const currentPath = window.location.pathname;

      // Extract clinicId from the URL path using split and destructuring
      const [, , clinicId] = currentPath.split('/');
      const numericClinicId = parseInt(clinicId, 10);
      // console.log("numericClinicId", numericClinicId);
      // Initialize qContent with default content
      let lifestyleHx = `Exercise frequency: ${formData.exerciseFrequency} per week\n`;
      lifestyleHx += `Average exercise time spent per session: ${formData.averageTimePerExercise} min\n`;
      lifestyleHx += `Drinking status: ${formData.drinkingFrequency} per week\n`;
      lifestyleHx += `Smoking status: ${formData.smokerStatus}`;

      // Modify qContent based on smokingStatus
      if (formData.smokerStatus === 'Smoker') {
        lifestyleHx = lifestyleHx.replace(/\n$/, ''); // Remove trailing newline if exists
        lifestyleHx += `\nSticks of cigarettes per day: ${formData.sticksPerDay}`;
      } else if (formData.smokerStatus === 'Ex-smoker') {
        lifestyleHx = lifestyleHx.replace(/\n$/, ''); // Remove trailing newline if exists
        lifestyleHx += `\nYear quit: ${formData.yearQuit}`;
      }

      // console.log("lifestyle", lifestyleHx);
      // Calculate gravida and para
      const gravida =
        parseInt(formData.numChildDelivery, 10) +
        parseInt(formData.numMiscAbortion, 10);
      const para = parseInt(formData.numChildDelivery, 10);

      // Initialize medicalHistoryContent
      let medicalHistoryContent = selectedDiseasesNoParentheses.join('\n');

      // Check if the meds = Yes
      if (formData.medStatus === 'Yes') {
        // Append the additional information to medicalHistoryContent
        medicalHistoryContent += `\nList of medications: ${formData.meds}`;
      }
      // Check if the gender is Female
      if (formData.gender === 'Female') {
        // Append the additional information to medicalHistoryContent
        medicalHistoryContent += `\nG${gravida} P${para}\nLMP: ${formData.lmp}`;
      }

      const formContent = {
        pastMedicalHistory: medicalHistoryContent,
        nerEntityExtraction: {
          clinicalTextExtraction: {
            disorder: [],
            event: [],
            finding: [],
            management: [],
            morphologicAbnormality: [],
            navigationalConcept: [],
            person: [],
            procedure: [],
            situation: [],
          },
          clinicalTextSnomedCodeMatch: {
            disorder: {},
            event: {},
            finding: {},
            management: {},
            morphologicAbnormality: {},
            navigationalConcept: {},
            person: {},
            procedure: {},
            situation: {},
          },
          snomedCodeMatch: {
            codes: [],
          },
        },
        snomedCodeDetails: {},
      };

      // console.log("PMHX Form Content", formContent);

      // Construct the dataToSend object with clinicId
      const dataToSend = {
        uuid: uuid,
        clinicId: numericClinicId,
        name: formData.name,
        nric: formData.nric.toUpperCase(), // Convert NRIC to uppercase
        questionnaires: [
          {
            qType: 'PatientDetails',
            qContent: [
              {
                birthDate: formData.birthDate,
                nric: formData.nric,
                passportCountryCode: formData.passportCountryCode,
                passportNumber: formData.passportNumber,
                gender: formData.gender,
                race: formData.race,
                mobileNumber: formData.mobileNumber,
                email: formData.email,
                streetAddress: formData.streetAddress,
                zipcode: formData.zipcode,
                daStatus: formData.daStatus,
                daName: formData.daName,
              },
            ],
          },
          {
            qType: 'PastMedicalHx',
            qContent: formContent || 'Nil significant medical history declared',
          },
          {
            qType: 'LifestyleHx',
            qContent: lifestyleHx,
          },
          {
            qType: 'FamilyHx',
            qContent: familyHx,
          },
        ],
      };

      // console.log('DataToSend', dataToSend);

      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/patient/questionnaires`,
        dataToSend,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200 || response.status === 201) {
        // Set showSuccessAlert to true to display the success alert
        setShowSuccessAlert(true);

        // After 10 seconds, hide the success alert
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 10000);

        // Wait for 20 seconds before reloading the window
        setTimeout(() => {
          window.location.reload();
        }, 20000);
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      // Check if the error response contains validation errors
      if (error.response && error.response.status === 422) {
        const { errors } = error.response.data;
        const errorFromBackend = errors.map((err) => err.msg).join('\n');
        const errorMessage = `Validation Error: ${errorFromBackend}`;
        setErrorMessage(errorMessage);
        setShowErrorAlert(true);

        // After 10 seconds, hide the error alert
        setTimeout(() => {
          setShowErrorAlert(false);
        }, 10000);
      } else {
        console.error('Error submitting data:', error);
      }
    }
  };

  const renderFields = (fields: any[]) => {
    // console.log('Rendering fields:', fields); // Add this line for debugging

    return fields.map((field: any, idx: number) => (
      <div key={idx} className="w-full md:w-1/2 px-3 mb-4">
        <label htmlFor={field.name} className={textStyleStrong}>
          {field.label}
          {field.required && <span className={requiredAsteriskStyle}>*</span>}
        </label>
        {field.type === 'select' ? (
          <select
            name={field.name}
            id={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            className={`${inputSelectorStyle(field.name)} ${errorBoxHighlight[field.name] ? 'border-red-500' : 'border-gray-300'}`}
          >
            <option value="">Select an option</option>
            {field.options.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={field.type}
            name={field.name}
            id={field.name}
            value={formData[field.name] || ''}
            onChange={handleInputChange}
            className={`${inputSelectorStyle(field.name)} ${errorBoxHighlight[field.name] ? 'border-red-500' : 'border-gray-300'}`}
          />
        )}
        {formErrorMessages[field.name] && (
          <p className="text-red-500 text-xs mt-1">
            {formErrorMessages[field.name]}
          </p>
        )}
      </div>
    ));
  };

  // Determine which sections to show based on smokerStatus
  const getFilteredSections = () => {
    if (!lifestyleHistory || !Array.isArray(lifestyleHistory.sections)) {
      return [];
    }

    const sections = lifestyleHistory.sections;

    // Always include "Lifestyle" section
    let filteredSections = sections.filter(
      (section: any) => section.title === 'Lifestyle',
    );

    // Conditionally include "Smoking History" or "Ex-smoker History" based on smokerStatus
    if (formData.smokerStatus === 'Smoker') {
      filteredSections = [
        ...filteredSections,
        ...sections.filter(
          (section: any) => section.title === 'Smoker History',
        ),
      ];
    } else if (formData.smokerStatus === 'Ex-smoker') {
      filteredSections = [
        ...filteredSections,
        ...sections.filter(
          (section: any) => section.title === 'Ex-smoker History',
        ),
      ];
    }

    return filteredSections;
  };

  const DiseaseTable: React.FC<DiseaseTableProps> = memo(
    ({
      chosenFamilyMembers,
      diseaseOptions,
      memberDiseasesMap,
      handleFamilyCheckboxChange,
    }) => {
      return (
        <table className="w-full border-collapse bg-white text-gray-800 dark:bg-gray-800 dark:border-gray-700 dark:text-white">
          <thead>
            <tr className="bg-gray-50 dark:bg-gray-700">
              <th className="px-4 py-2 text-left w-1/5 sm:w-1/6">Family Members</th>
              {chosenFamilyMembers.map((member, index) => (
                <th key={index} className="px-4 py-2 text-center w-1/8 font-normal">
                  {member}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {diseaseOptions.map((disease) => (
              <tr key={disease}>
                <td className="px-4 py-2 text-left w-1/5 sm:w-1/6">
                  <label htmlFor={disease} className={textStyle}>
                    {disease}
                  </label>
                </td>
                {chosenFamilyMembers.map((member) => (
                  <td key={member} className="px-4 py-2 text-center w-1/8">
                    <input
                      type="checkbox"
                      className={inputCheckboxStyle}
                      id={`${disease}-${member}`}
                      name={`${disease}-${member}`}
                      checked={memberDiseasesMap[member]?.includes(disease)}
                      onChange={() => handleFamilyCheckboxChange(disease, member)}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
  );
  
  
  return (
    <div className="min-h-screen py-10 bg-gray-100 flex items-center justify-center">
      {showSuccessAlert && (
        <SuccessAlert
          text="Questionnaire submitted successfully!"
          show={showSuccessAlert}
        />
      )}
      {showErrorAlert && (
        <ErrorAlert text={errorMessage} show={showErrorAlert} />
      )}

      <div className="container max-w-screen-lg mx-auto">
        <img src={cover} alt="logo" className="" />
        <div className="bg-white rounded shadow-lg py-6 px-8">
          <form onSubmit={handleSubmit}>
            {loading ? (
              <div className="flex flex-col items-center justify-center">
                <h1 className="text-2xl font-bold mt-10 mb-4 text-black">
                  Loading...
                </h1>
                {/* You can include a loading spinner here */}
                <div className="loader">Loading...</div>
              </div>
            ) : (
              <>
                <h1 className="text-2xl font-bold mt-10 mb-4 text-black">
                  Personal & Medical History
                </h1>
                {formTemplate &&
                  formTemplate.sections
                    .filter(
                      (section: any) =>
                        section.title !== 'Female Specific History' ||
                        formData.gender === 'Female',
                    )
                    .map((section: any, index: number) => (
                      <div key={index}>
                        <div>
                          <h2 className={titleStyle}>{section.title}</h2>
                        </div>
                        <div className="flex flex-wrap -mx-3 mt-2">
                          {renderFields(section.fields)}
                        </div>
                        <hr className="h-px my-4 bg-gray-400 border-0 dark:bg-gray-700" />
                      </div>
                    ))}

                {/* Health and Lifestyle Section */}
                {getFilteredSections().length > 0 ? (
                  getFilteredSections().map((section: any, index: number) => (
                    <div key={index}>
                      <div className="text-black py-6">
                        <h2 className={titleStyle}>{section.title}</h2>
                      </div>
                      <div className="flex flex-wrap -mx-3 mt-2">
                        {renderFields(section.fields)}
                      </div>
                      <hr className="h-px my-4 bg-gray-400 border-0 dark:bg-gray-700" />
                    </div>
                  ))
                ) : (
                  <p>No lifestyle history data available.</p>
                )}

                {/* Medical History Section */}
                <div>
                  <div className="text-black py-6">
                    <h2 className={titleStyle}>Medical History</h2>
                    <p className="text-sm">
                      Please select relevant medical conditions.
                    </p>
                  </div>
                  <div className="lg:col-span-3 flex flex-col space-y-4">
                    {medicalHistory.map((section, index) => (
                      <div key={index}>
                        <p className="font-medium text-base text-black py-2">
                          {section.category}
                        </p>
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-4 lg:grid-cols-4">
                          {section.diseases.map((condition, conditionIndex) => (
                            <div key={conditionIndex} className="md:col-span-2">
                              <input
                                type="checkbox"
                                className={inputCheckboxStyle}
                                id={condition}
                                checked={selectedConditions.includes(condition)}
                                onChange={() =>
                                  handleConditionSelection(condition)
                                }
                              />{' '}
                              <label className={textStyle} htmlFor={condition}>
                                {condition}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr className="h-px mt-6 mb-6 bg-gray-400 border-0 dark:bg-gray-700" />
                  <FormAdditionalMedHx
                    additionalMedHx={additionalMedHx}
                    handleTextareaChange={handleAdditionalMedHxChange}
                  />
                </div>

                {/* Family History Section */}
                <div>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-2">
                    <div className="text-black py-6">
                      <h2 className={titleStyle}>Family History</h2>
                      <p className="text-sm">
                        Please select relevant medical conditions.
                      </p>
                    </div>

                    <div className="md:col-span-2 overflow-x-auto">
  <div className="min-w-full">
    <DiseaseTable
      chosenFamilyMembers={chosenFamilyMembers}
      diseaseOptions={diseaseOptions}
      memberDiseasesMap={memberDiseasesMap}
      handleFamilyCheckboxChange={handleFamilyCheckboxChange}
    />
  </div>
                      <hr className="h-px mt-6 mb-6 bg-gray-400 border-0 dark:bg-gray-700" />
                      <FormAdditionalFMHx
                        additionalFmHx={additionalFmHx}
                        handleTextareaChange={handleAdditionalFmHxChange}
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full flex-grow text-white bg-gradient-to-r bg-primaryColor-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-base px-5 py-4 text-center mb-2 ml-2"
                >
                  Submit
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default Questionnaire;
