import React from 'react';

interface MenuProps {
  x: number;
  y: number;
  options: { 
    label: string; 
    onClick: () => void; 
    isHighlighted?: boolean;  // Optional boolean to highlight the option
  }[]; 
  onClose: () => void;
}

const Menu: React.FC<MenuProps> = ({ x, y, options = [], onClose }) => {
  return (
    <div
      style={{
        position: 'absolute',
        left: x,
        top: y,
      }}
      className="bg-white border border-gray-300 rounded-lg shadow-lg z-50"
    >
      <ul className="text-sm">
        {options && options.length > 0 ? (
          options.map((option, index) => (
            <li
              key={index}
              onClick={option.onClick}
              className={`cursor-pointer px-4 py-2 ${
                option.isHighlighted 
                  ? 'bg-primaryColor-50 text-primaryColor-500 font-semibold'  // Highlighted style
                  : 'text-gray-700 hover:bg-primaryColor-200 hover:text-white' // Regular style
              }`}
            >
              {option.label}
            </li>
          ))
        ) : (
          <li className="text-gray-500 px-4 py-2">No options available</li> // Fallback message
        )}
      </ul>
    </div>
  );
};

export default Menu;
