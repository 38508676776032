import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
const Legend = () => {
  return (
    <div className="ml-12 mt-2 flex items-center justify-between w-[calc(100%-120px)]">
      <div className="flex items-center mb-1">
        <div className="w-2.5 h-2.5 bg-green-300 border border-gray-300"></div>
        <span className="ml-1 mr-2 text-xs">Indian</span>
      </div>
      <div className="flex items-center mb-1">
        <div className="w-2.5 h-2.5 bg-blue-500 border border-gray-300"></div>
        <span className="ml-1 mr-2 text-xs">Malay</span>
      </div>
      <div className="flex items-center mb-1">
        <div className="w-2.5 h-2.5 bg-yellow-300 border border-gray-300"></div>
        <span className="ml-1 text-xs">Chinese</span>
      </div>
      <div className="flex items-center mb-1">
        <svg
          className="w-2.5 h-2.5 text-black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M12 .587l3.668 7.568 8.332 1.208-6.042 5.852 1.427 8.332L12 18.896l-7.385 3.878 1.427-8.332-6.042-5.852 8.332-1.208z" />
        </svg>{' '}
        <span className="ml-1 text-xs">Your risk</span>
      </div>
    </div>
  );
};

const FraminghamSGGraph = ({ race, gender, framinghamForms }) => {
  // console.log('framinghamForms received from parent', framinghamForms);
  const [combinedData, setCombinedData] = useState([]);

  // Define the areaData
  const areaData = [
    ['Total Points', 'Indian', 'Malay', 'Chinese'],
    [-5, 0, 0, 0],
    [-4, 0, 0, 0],
    [-3, 0, 0, 0],
    [-2, 0, 0, 0],
    [-1, 0, 0, 0],
    [0, 0, 0, 0],
    [1, 0, 0, 0],
    [2, 1, 1, 0],
    [3, 1, 1, 1],
    [4, 1, 1, 1],
    [5, 2, 1, 1],
    [6, 2, 2, 1],
    [7, 3, 2, 1],
    [8, 4, 3, 2],
    [9, 5, 3, 2],
    [10, 6, 4, 3],
    [11, 7, 5, 3],
    [12, 10, 7, 4],
    [13, 12, 9, 5],
    [14, 15, 11, 6],
    [15, 19, 12, 7],
    [16, 24, 14, 8],
    [17, 30, 17, 10],
    [18, 37, 20, 11],
    [19, 45, 24, 13],
    [20, 54, 28, 15],
  ];


  // Map of race names to column indices
  const raceIndex = {
    Indian: 1,
    Malay: 2,
    Chinese: 3,
  };

  // Function to calculate risk based on race and total points
  const calculateRisk = (race, totalPoints) => {
    // Convert totalPoints to a number
    const numericTotalPoints = Number(totalPoints);

    // Get the risk for the selected totalPoints
    const riskRowIndex = areaData.findIndex(
      (row) => row[0] === numericTotalPoints,
    ); // Get the row for the specific totalPoints

    // Retrieve the risk based on race
    let risk = null; // Default value if not found
    if (riskRowIndex !== -1) {
      // Ensure the row was found
      const columnIndex = raceIndex[race]; // Get the index for the given race
      risk = areaData[riskRowIndex][columnIndex]; // Get the risk value
    }

/*     // console.log(
      'Risk for race',
      race,
      'at total points',
      numericTotalPoints,
      'is:',
      risk,
    ); // Should log 3 for Chinese at 10 points */
    return risk;
  };

  // Effect to prepare the combined data
  useEffect(() => {
    // Ensure framinghamForms is not empty and has valid data
    if (framinghamForms.length > 0) {
      // Access the first Framingham form's riskData
      const totalPoints = framinghamForms[0].formContent.riskData.totalPoints; // Assuming you're using the first form for this calculation
    // console.log('Total points:', totalPoints);

      // Access the race from the form content
      const risk = calculateRisk(race, totalPoints); // Get the risk for the provided race and total points

      // Create a DataTable for combined data
      const newCombinedData = [
        ['Total Points', 'Indian', 'Malay', 'Chinese', 'Patient Risk'],
      ];

      // Add area data to combined data
      for (let i = 1; i < areaData.length; i++) {
        // Start from 1 to skip header row
        newCombinedData.push([
          areaData[i][0],
          areaData[i][1],
          areaData[i][2],
          areaData[i][3],
          null, // Placeholder for patient risk
        ]);
      }

      // Add the patient risk to the combined data
      newCombinedData.push([totalPoints, null, null, null, risk]); // Add totalPoints and the retrieved risk

      setCombinedData(newCombinedData); // Update the state with combined data
    } else {
      console.warn('No Framingham forms available.');
    }
  }, [race, framinghamForms]); // Dependency array

  // Chart options
  const areaOptions = {
    title: '10-Year Cardiovascular Risk',
    hAxis: {
      title: 'Total Points',
      minValue: -5,
      maxValue: 20,
      ticks: [-5, 0, 5, 10, 15, 20], // Specify ticks
      gridlines: { count: 1 }, // Optional: customize the number of gridlines
      logScale: false, // Ensure logScale is false
    },
    vAxis: {
      title: 'Risk (%)',
      minValue: 0,
      maxValue: 60,
    },
    series: {
      0: { label: 'Indian', color: '#40f040', type: 'area' },
      1: { label: 'Malay', color: '#40b0f0', type: 'area' },
      2: { label: 'Chinese', color: '#f0c040', type: 'area' },
      3: {
        label: 'Patient Risk',
        color: 'black',
        type: 'scatter',
        pointShape: 'star',
        sides: 5,
        dent: 0.5,
      },
    },
    legend: 'none',
  };

  return (
    <div>
      {framinghamForms.length > 0 && (
        <div>
          {/* Display risk score */}
          <div className="mb-4">
          Based on your demographic profile ({race} {gender}), you have a{' '}
            <b>{framinghamForms[0].formContent.riskData.risk} </b>risk of
            experiencing a cardiovascular event within the next 10 years.
          </div>
                {/* Risk Calculation */}

          <div className="mb-4">
            Age ({framinghamForms[0].formContent.riskData.agePoints} point) + blood
            pressure control ({framinghamForms[0].formContent.riskData.bpPoints} point) + cholesterol (
            {framinghamForms[0].formContent.riskData.cholesterolPoints} point) +
            smoking status ({framinghamForms[0].formContent.riskData.smokerPoints} point) ={' '}
            {framinghamForms[0].formContent.riskData.totalPoints} point(s)
          </div>
          <div className="overflow-x-auto">
  <table className="table-auto w-full text-xs border-collapse border border-gray-300 mb-4">
            <thead>

        </thead>
        <tbody>
          {/* Total Points Row */}
          <tr className="bg-gray-100">
            <td className="border border-gray-300 p-2">Total Points</td>
            {areaData.slice(1).map((row, index) => (
              <td key={index} className="border border-gray-300 p-2">
                {row[0]}
              </td>
            ))}
          </tr>

          {/* Indian Row */}
          {race=='Indian' && (
          <tr className="bg-white">
            <td className="border border-gray-300 p-2">Risk %</td>
            {areaData.slice(1).map((row, index) => (
              <td key={index} className="border border-gray-300 p-2">
                {row[1]}
              </td>
            ))}
          </tr>
          )}
          {/* Malay Row */}
          {race=='Malay' && (
          <tr className="bg-gray-100">
            <td className="border border-gray-300 p-2">Risk %</td>
            {areaData.slice(1).map((row, index) => (
              <td key={index} className="border border-gray-300 p-2">
                {row[2]}
              </td>
            ))}
          </tr>)}

          {/* Chinese Row */}
          {race=='Chinese' && (
          <tr className="bg-white">
            <td className="border border-gray-300 p-2">Risk %</td>
            {areaData.slice(1).map((row, index) => (
              <td key={index} className="border border-gray-300 p-2">
                {row[3]}
              </td>
            ))}
          </tr>
          )}
        </tbody>
      </table>
      </div>
        </div>
      )}

      {/* Chart rendering */}
      {combinedData.length > 0 && (
        <Chart
          chartType="ComboChart"
          data={combinedData}
          options={areaOptions}
          width="100%"
          height="500px"
          legendToggle
        />
      )}

      <Legend />
      <div className="text-xs py-4">
      <p>
        These risk scores are derived from the Framingham-based NCEP ATP III 10-Year Risk Score Tables
        which
        have been recalibrated using data from the Singapore Population Health Studies – Multi-ethnic
        Cohort
        Phase 1 (MEC1) and National Registry of Diseases Office. This recalibration (SG-FRS-2023) was
        carried
        out as part of a collaboration between investigators at the Singapore Ministry of Health and Saw
        Swee
        Hock School of Public Health, National University of Singapore and National University Health
        System.
    </p>
    <div className="pt-4">
    <strong>Reference: </strong>
    <a
        href="https://www.ace-hta.gov.sg/docs/default-source/acgs/additional-resource-for-cv-risk-assessment-using-sg-frs-2023.pdf?sfvrsn=965de984_9">
        Agency for Care Effectivness - Singapore-modified Framingham Risk Score 2023 (SG-FRS-2023)
    </a>
    </div>
      </div>
    </div>
  );
};

export default FraminghamSGGraph;
