import React, { useEffect, useState } from 'react';
import BMIRangeChart from './BMIRangeChart';

interface BodyMassIndexProps {
  measureData: {
    weight: number;
    height: number;
    bmi: number;
    bmiCategory: string;
    createdAt: string;
    PatientId: number;
  };
}
const commonpClassStyles = 'text-sm text-gray-800 h-22';

const BodyMassIndex: React.FC<BodyMassIndexProps> = ({ measureData }) => {
  const [height, setHeight] = useState<number | null>(null);
  const [weight, setWeight] = useState<number | null>(null);
  const [bmi, setBmi] = useState<number | null>(null);
  const [bmiCategory, setBmiCategory] = useState('');

  useEffect(() => {
    // Set state variables from the measureData prop
    setHeight(measureData.height);
    setWeight(measureData.weight);
    setBmi(measureData.bmi);
    setBmiCategory(measureData.bmiCategory);
  }, [measureData]);

  if (height === null || weight === null) {
    return null; // Render nothing until data is available
  }

  // Define the biometrics content based on BMI value
  let biometricsContent;
  if (measureData.bmi > 23) {
    // Display specific content when BMI is greater than 23
    biometricsContent = (
      <div>
        <p className={commonpClassStyles}>
          Your Body Mass Index (BMI) category is <strong>{bmiCategory}</strong>.
          BMI is used by the doctors to assess your risk for diseases like high
          blood pressure, high cholesterol, and diabetes. 
        </p>
      </div>
    );
  } else {
    // Display default content when BMI is not greater than 23
    biometricsContent = (
      <p className={commonpClassStyles}>
        Your Body Mass Index (BMI) category is <strong>{bmiCategory}</strong>.
        Your weight is within normal range. Continue with what you are currently
        doing to keep your weight within normal range.
      </p>
    );
  }

  // Check if measureData is empty
  if (!measureData || Object.keys(measureData).length === 0) {
    return (
      <div className="text-red-600">
        Missing data. Please contact your clinic.
      </div>
    );
  }
  return (
    <div id="bmi" className="flex">
      <div className="w-full">
      {biometricsContent}
        <div className="w-96 overflow-x-auto">
          <div className="border rounded-lg dark:border-gray-700 overflow-scroll">
            <table className="divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="h-14 px-3 py-2 sm:px-4 sm:py-3 w-1/6  text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Height (cm)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-4 sm:py-3 w-1/6  text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    Weight (kg)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-4 sm:py-3 w-1/6  text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    BMI (kg/m2)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-2 sm:px-4 sm:py-3 w-2/6 text-left text-xs font-medium text-gray-500 sm:uppercase"
                  >
                    BMI Category
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="h-10 px-4 py-2 sm:px-6 sm:py-3 w-1/4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                    {height}
                  </td>
                  <td
                    className={`px-4 py-2 sm:px-6 sm:py-3 w-1/4 whitespace-nowrap text-sm  dark:text-gray-200
                    ${measureData.bmi < 18.5 ? 'text-amber-400' : ''}
                    ${measureData.bmi >= 18.5 && measureData.bmi < 23 ? 'text-green-700' : ''}
                    ${measureData.bmi >= 23 && measureData.bmi < 30 ? 'text-amber-400' : ''}
                    ${measureData.bmi >= 30 && measureData.bmi < 35 ? 'text-pink-500' : ''}
                    ${measureData.bmi >= 35 && measureData.bmi < 40 ? 'text-red-700' : ''}
                    ${measureData.bmi >= 40 ? 'text-red-800' : ''}
                    `}
                  >
                    {weight}
                  </td>
                  <td
                    className={`px-3 py-2 sm:px-6 sm:py-3 w-1/4 whitespace-nowrap text-sm  dark:text-gray-200
                    ${measureData.bmi < 18.5 ? 'text-amber-400' : ''}
                    ${measureData.bmi >= 18.5 && measureData.bmi < 23 ? 'text-green-700' : ''}
                    ${measureData.bmi >= 23 && measureData.bmi < 30 ? 'text-amber-400' : ''}
                    ${measureData.bmi >= 30 && measureData.bmi < 35 ? 'text-pink-500' : ''}
                    ${measureData.bmi >= 35 && measureData.bmi < 40 ? 'text-red-700' : ''}
                    ${measureData.bmi >= 40 ? 'text-red-800' : ''}
                    `}
                  >
                    {measureData.bmi.toFixed(2)}
                  </td>
                  <td className="px-3 py-2 sm:px-6 sm:py-3 w-1/4 whitespace-nowrap text-sm dark:text-gray-200">
                    {bmiCategory === 'Normal' ? (
                      <span className="px-1 py-1 rounded text-xs bg-green-100 text-green-700">
                        {bmiCategory}
                      </span>
                    ) : bmiCategory === 'Mild Thinness' ? (
                      <span className="px-1 py-1 rounded text-xs bg-amber-100 text-amber-600">
                        {bmiCategory}
                      </span>
                    ) : bmiCategory === 'Moderate Thinness' ? (
                      <span className="px-1 py-1 rounded text-xs bg-pink-100 text-pink-700">
                        {bmiCategory}
                      </span>
                    ) : bmiCategory === 'Severe Thinness' ? (
                      <span className="px-1 py-1 rounded text-xs bg-red-100 text-red-800">
                        {bmiCategory}
                      </span>
                    ) : bmiCategory === 'Overweight' ? (
                      <span className="px-1 py-1 rounded text-xs bg-amber-100 text-amber-600">
                        {bmiCategory}
                      </span>
                    ) : bmiCategory === 'Obese Class I' ? (
                      <span className="px-1 py-1 rounded text-xs bg-pink-100 text-pink-700">
                        {bmiCategory}
                      </span>
                    ) : (
                      <span className="px-1 py-1 rounded text-xs bg-red-100 text-red-800">
                        {bmiCategory}
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="hidden sm:block w-1/2">
        <BMIRangeChart />
      </div>
    </div>
  );
};

export default BodyMassIndex;
