import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AlertTriangle, Check } from 'lucide-react';
import InfoPopup from '../alerts/InfoPopup';
import useTemplate from '../../hooks/useTemplate';
import validateForm from '../../function/formValidator';
import validateMissingFields from '../../function/missingFieldValidator';
import formValidationConfig from '../../config/formValidationConfig.json';

const textStyle = 'text-sm font-medium text-gray-900 dark:text-white';
const requiredAsteriskStyle = 'text-red-500 mx-1';
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

const raceOptions = [
  { value: 'Caucasian', label: 'Caucasian' },
  { value: 'Chinese', label: 'Chinese' },
  { value: 'Eurasian', label: 'Eurasian' },
  { value: 'Indian', label: 'Indian' },
  { value: 'Malay', label: 'Malay' },
  { value: 'Others', label: 'Others' },
];

const daStatusOptions = [
  { value: 'NKDA', label: 'NKDA' },
  { value: 'Has Drug Allergy', label: 'Has Drug Allergy' },
];
interface FormActualizeVisitProps {
  patientDetails: FormData;
  clinicId: string;
  uuid: string;
}


interface FormData {
  name: string;
  nric: string;
  gender: string;
  race: string;
  birthDate: string;
  mobileNumber: string;
  email: string;
  streetAddress: string;
  zipcode: string;
  daStatus: string;
  daName: string;
  healthScreeningPackageUuid: string;
  visitDate: string;
}

type FormFieldConfig = {
  name: keyof FormData;
  label: string;
  type: 'text' | 'select' | 'date';
  options?: { value: string; label: string }[];
  required?: boolean;
};

type FormErrorMessages = Partial<FormData>;

const FormActualizeVisit: React.FC<FormActualizeVisitProps> = ({ patientDetails, clinicId, uuid }) => {
  const { templateData, loading } = useTemplate();

  const [formData, setFormData] = useState<FormData>({
    name: '',
    nric: '',
    gender: '',
    race: '',
    birthDate: '',
    mobileNumber: '',
    email: '',
    streetAddress: '',
    zipcode: '',
    daStatus: '',
    daName: '',
    healthScreeningPackageUuid: '',
    visitDate: '',
  });

  const [healthScreeningPackageOptions, setHealthScreeningPackageOptions] = useState<{ value: string; label: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState<FormErrorMessages>({});
  const [errorBoxHighlight, setErrorBoxHighlight] = useState<Record<keyof FormData, boolean>>({
    name: false,
    nric: false,
    gender: false,
    race: false,
    birthDate: false,
    mobileNumber: false,
    email: false,
    streetAddress: false,
    zipcode: false,
    daStatus: false,
    healthScreeningPackageUuid: false,
    visitDate: false,
  });

  useEffect(() => {
    // Fetch health screening packages from templateData if not loading
    if (!loading && templateData['HealthScreeningPackage']) {
      const healthPackages = templateData['HealthScreeningPackage'];
      if (Array.isArray(healthPackages)) {
        const options = healthPackages.map((pkg: { healthScreeningPackageUuid: string; healthScreeningPackageName: string }) => ({
          value: pkg.healthScreeningPackageUuid,
          label: pkg.healthScreeningPackageName,
        }));
        setHealthScreeningPackageOptions(options);
      }
    }

    // Populate formData if patientDetails is available
    if (patientDetails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...patientDetails,
        visitDate: patientDetails.visitDate || '', // Provide default value if missing
        healthScreeningPackageUuid: patientDetails.healthScreeningPackageUuid || '', // Provide default value if missing
      }));
      // console.log('Form data populated with patient details:', patientDetails);
      // Reset error highlights
      setErrorBoxHighlight({
        name: false,
        nric: false,
        gender: false,
        race: false,
        birthDate: false,
        mobileNumber: false,
        email: false,
        streetAddress: false,
        zipcode: false,
        daStatus: false,
        healthScreeningPackageUuid: false,
        visitDate: false,
      });
    }
  }, [uuid, loading, templateData, patientDetails]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear any error state for the current field
    setShowErrorAlert(false);
    setFormErrorMessages({});
    setErrorBoxHighlight((prev) => ({
      ...prev,
      [name]: false,
    }));

    // Run validation on the updated form data
    setTimeout(() => {
      validateForm(formData, formValidationConfig, setFormErrorMessages, Object.keys(formData));
    }, 0);
  };

  const formFields: FormFieldConfig[] = [
    { name: 'name', label: 'Name', type: 'text', required: true },
    { name: 'nric', label: 'NRIC', type: 'text', required: true },
    { name: 'gender', label: 'Gender', type: 'select', options: genderOptions, required: true },
    { name: 'race', label: 'Race', type: 'select', options: raceOptions, required: true },
    { name: 'birthDate', label: 'Birth Date', type: 'date', required: true },
    { name: 'mobileNumber', label: 'Mobile Number', type: 'text', required: true },
    { name: 'email', label: 'Email', type: 'text', required: true },
    { name: 'streetAddress', label: 'Street Address', type: 'text', required: true },
    { name: 'zipcode', label: 'Zip Code', type: 'text', required: true },
    { name: 'daStatus', label: 'DA Status', type: 'select', options: daStatusOptions, required: true },
    { name: 'daName', label: 'DA Name', type: 'text', required: false },
    { name: 'healthScreeningPackageUuid', label: 'Health Screening Package', type: 'select', options: healthScreeningPackageOptions, required: true },
    { name: 'visitDate', label: 'Visit Date', type: 'date', required: true },
  ];

  const handleSubmit = async () => {
    // Validate missing fields
    const missingFields = validateMissingFields(formData, formValidationConfig, Object.keys(formData));
    if (missingFields.length > 0) {
      const errorObject: FormErrorMessages = {};
      missingFields.forEach((field) => {
        errorObject[field.name] = `${field.label} is required.`;
      });

      // Set error messages and highlight missing fields
      setFormErrorMessages(errorObject);
      const highlightState: Record<keyof FormData, boolean> = {};
      missingFields.forEach((field) => {
        highlightState[field.name] = true;
      });
      setErrorBoxHighlight(highlightState);
      return;
    }

    try {
      const formDataWithUuid = { ...formData, uuid, clinicId };
      const response = await axios.post(`${REACT_APP_API_BASE_URL}/visit/actualize`, formDataWithUuid, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (response.status === 200) {
        setShowSuccessAlert(true);
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_FRONTEND_BASE_URL}/patient/${response.data.uuid}`;
        }, 2000);
      }
    } catch (error) {
      setShowErrorAlert(true);
      setErrorMessage('Error occurred while submitting form');
    }
  };

  return (
    <div>
      {showErrorAlert && (
        <InfoPopup color="red" boldText="Alert!" text={errorMessage} icon={AlertTriangle} />
      )}
      {showSuccessAlert && (
        <InfoPopup color="green" boldText="Success!" text="Visit actualized." icon={Check} />
      )}

      <form className="grid grid-cols-2 gap-4">
        {formFields.map((field) => (
          <div key={field.name} className="form-group">
            <label className={textStyle}>
              {field.label}
              {field.required && <span className={requiredAsteriskStyle}>*</span>}
            </label>

            {field.type === 'text' && (
              <input
                type="text"
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${errorBoxHighlight[field.name] ? 'border-red-500' : 'border-gray-300'}`}
                required={field.required}
              />
            )}

            {field.type === 'date' && (
              <input
                type="date"
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${errorBoxHighlight[field.name] ? 'border-red-500' : 'border-gray-300'}`}
                required={field.required}
              />
            )}

            {field.type === 'select' && field.options && (
              <select
                name={field.name}
                value={formData[field.name]}
                onChange={handleInputChange}
                className={`bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 ${errorBoxHighlight[field.name] ? 'border-red-500' : 'border-gray-300'}`}
                required={field.required}
              >
                <option value="">Select {field.label}</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}

            {formErrorMessages[field.name] && (
              <p className="text-red-500 text-xs mt-1">{formErrorMessages[field.name]}</p>
            )}
          </div>
        ))}
      </form>

      <button
        onClick={handleSubmit}
        className="mt-4 w-full h-14 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        type="button"
      >
        Submit
      </button>
    </div>
  );
};

export default FormActualizeVisit;
