import { useState, useEffect } from 'react';
import axios from 'axios';
import convertUTCToSGTimestamp from '../function/convertUTCToSGTimestamp';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Hook to fetch patient details
const usePatientDetails = (uuid: string | undefined) => {
  const [fetchedPatientDetails, setFetchedtPatientDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchPatientDetails = async () => {
      if (!uuid) return; // Ensure UUID exists before making the API call

      setLoading(true);
      try {
        // First API call to get visit details using the UUID
        const visitResponse = await axios.get(
          `${REACT_APP_API_BASE_URL}/visit/uuid/${uuid}`,
          { withCredentials: true }
        );

        const patientId = visitResponse.data.PatientId;
        if (!patientId) {
          console.error('Patient ID not found in visit details');
          setError(true);
          return;
        }

        const formattedVisitDate = convertUTCToSGTimestamp(visitResponse.data.visitDate);


        // Second API call to get patient details using the PatientId
        const patientResponse = await axios.get(
          `${REACT_APP_API_BASE_URL}/patients/id/${patientId}`,
          { withCredentials: true }
        );

        const {
          name,
          nric,
          daStatus,
          daName,
          gender,
          race,
          mobileNumber,
          email,
          streetAddress,
          zipcode,
          passportCountryCode,
          passportNumber,
        } = patientResponse.data;

        const birthdate = convertUTCToSGTimestamp(patientResponse.data.birthDate);

        const patientDetailsFetched = {
          name,
          nric,
          birthDate: birthdate,
          gender,
          race,
          mobileNumber,
          email,
          streetAddress,
          zipcode,
          daStatus,
          daName,
          passportCountryCode,
          passportNumber,
          visitDate: formattedVisitDate,
        };

        setFetchedtPatientDetails(patientDetailsFetched);
        // console.log('Patient details fetched:', patientDetailsFetched);
      } catch (error) {
        console.error('Error fetching patient details:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchPatientDetails();
  }, [uuid]);

  return { fetchedPatientDetails, loading, error };
};

export default usePatientDetails;
