import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ToolbarButton from '../ToolbarButton';
import {
  Pencil,
  Copy,
  Check,
  Printer,
  Link2,
  AlertTriangle,
  ChevronDown,
} from 'lucide-react';
import useTemplate from '../../hooks/useTemplate';
import InfoPopup from '../alerts/InfoPopup';
import LoadingPopup from '../LoadingPopup';
import convertUTCToSGTimestamp from '../../function/convertUTCToSGTimestamp';
import Menu from '../Menu';

const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL;

interface VisitData {
  id: number;
  createdAt: string;
  uuid: string;
  visitDate: string;
  healthScreeningPackageUuid?: string;
  healthScreeningPackageName?: string;
}

interface CardVisitListProps {
  patientId: number;
}

const CardVisitList: React.FC<CardVisitListProps> = ({ patientId }) => {
  const [visitData, setVisitData] = useState<VisitData[]>([]);
  // Initialize a state to track copy success for each item
  const [copyURLSuccesses, setCopyURLSuccesses] = useState<{
    [uuid: string]: boolean;
  }>({});
  const [copySuccesses, setCopySuccesses] = useState<{
    [uuid: string]: boolean;
  }>({});
  const [initialCardCount, setInitialCardCount] = useState(3); // Number of cards to display initially
  const { uuid: urlUUID } = useParams<{ uuid: string }>();
  const { templateData } = useTemplate();
  const [ error, setError] = useState(false);
  const [ errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPackageUuid, setSelectedPackageUuid] = useState<string | null>(
    null,
  );

  const [healthScreeningPackages, setHealthScreeningPackages] = useState<any[]>(
    [],
  ); // Stores the list of packages

  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    options: any[];
  } | null>(null);

  useEffect(() => {
    const closeMenu = () => setContextMenu(null);
    document.addEventListener('click', closeMenu);

    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);

  const fetchVisitData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URL}/visit/all/${patientId}`,
        { withCredentials: true }
      );
  
      const visitData = response.data;
  
      // Update visitData with formatted registration date
      const updatedVisitData = visitData.map((item) => ({
        ...item,
        registrationDate: convertUTCToSGTimestamp(item.createdAt),
      }));
  
      // Sort the visits by visitDate (most recent first)
      const sortedVisitData = updatedVisitData.sort((a, b) => {
        // Parse the visitDate string to a Date object and compare them
        return new Date(b.visitDate).getTime() - new Date(a.visitDate).getTime();
      });
  
      const enhancedVisitData = sortedVisitData.map((visit) => {
        if (visit.healthScreeningPackageUuid) {
          const matchedPackage = healthScreeningPackages.find(
            (hsPackage) =>
              hsPackage.healthScreeningPackageUuid === visit.healthScreeningPackageUuid
          );
  
          if (matchedPackage) {
            return {
              ...visit,
              healthScreeningPackageName: matchedPackage.healthScreeningPackageName,
            };
          }
        }
  
        return {
          ...visit,
          healthScreeningPackageName: 'No package selected',
        };
      });
  
      // Update state with the enhanced visit data
      setVisitData(enhancedVisitData);
    } catch (error) {
      console.error('Error fetching visit data:', error);
    } finally {
      setIsLoading(false); // Set loading to false after data fetch is done
    }
  };
  

  // Fetch visit data when either patientId or healthScreeningPackages changes
  useEffect(() => {
    if (patientId && healthScreeningPackages.length > 0) {
      fetchVisitData();
    }
  }, [patientId, healthScreeningPackages]);

  useEffect(() => {
    if (templateData.HealthScreeningPackage) {
      setHealthScreeningPackages(templateData.HealthScreeningPackage);
    }
  }, [templateData]);

  const handleRightClick = (event: React.MouseEvent, item: VisitData) => {
    event.preventDefault();

    const matchedPackage = healthScreeningPackages.find(
      (hsPackage) =>
        hsPackage.healthScreeningPackageUuid ===
        item.healthScreeningPackageUuid,
    );

    const healthScreeningOptions = healthScreeningPackages.map((hsPackage) => {
      const isHighlighted =
        matchedPackage &&
        hsPackage.healthScreeningPackageUuid ===
          matchedPackage.healthScreeningPackageUuid;
      return {
        label: hsPackage.healthScreeningPackageName,
        onClick: () =>
          handlePackageSelection(
            hsPackage.healthScreeningPackageUuid,
            item.uuid,
          ),
        isHighlighted,
      };
    });

    if (!matchedPackage) {
      healthScreeningOptions.push({
        label: 'No package selected',
        onClick: () => handlePackageSelection(null, item.uuid),
        isHighlighted: false,
      });
    }

    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      options: healthScreeningOptions,
    });
  };

  const handlePackageSelection = async (
    packageUuid: string | null,
    visitUuid: string,
  ) => {
    if (packageUuid && packageUuid !== selectedPackageUuid) {
      const visit = {
        uuid: visitUuid,
        healthScreeningPackageUuid: packageUuid,
        PatientId: patientId,
      };

      try {
        const visitResponse = await axios.post(
          `${REACT_APP_API_BASE_URL}/visit`,
          visit,
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          },
        );

        // Trigger a page reload to reflect the updated state
        window.location.reload();
      } catch (error) {
        console.error('Error updating package:', error);
      }
    }
  };

  const handleCopyReportURL = (event: React.MouseEvent, uuid: string) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    // Construct the URL to be copied using string interpolation
    const reportURL = `${REACT_APP_FRONTEND_BASE_URL}/passwordcheck/${uuid}`;
    // Copy the URL to the clipboard
    const el = document.createElement('textarea');
    el.value = reportURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Set the copy success for the specific UUID to true
    setCopyURLSuccesses((prevCopyURLSuccesses) => ({
      ...prevCopyURLSuccesses,
      [uuid]: true,
    }));

    // Reset copySuccess after a short delay
    setTimeout(() => {
      setCopyURLSuccesses((prevCopyURLSuccesses) => ({
        ...prevCopyURLSuccesses,
        [uuid]: false,
      }));
    }, 3000); // Reset copySuccess after 3 seconds
  };

  const handlePrintPdf = async (event: React.MouseEvent, uuid: string) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    setIsLoading(true); // Set loading state to true

    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URL}/pdf/report`,
        {
          uuid,
        },
        {
          withCredentials: true, // This ensures cookies are sent with the request
          responseType: 'arraybuffer', // Important: Set responseType to 'arraybuffer' to handle binary data correctly
        },
      );

      // console.log('response:', response); // Log the entire response object for debugging

      // Handle successful response (if response contains data)
      if (response.status === 200 && response.data) {
        // Create a Blob from the PDF data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const pdfUrl = window.URL.createObjectURL(pdfBlob);

        // Create a link to download the file
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open in a new tab
        link.download = `report_${uuid}.pdf`; // Optional: set the file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element

        // Optionally, revoke the object URL after use (to free up memory)
        window.URL.revokeObjectURL(pdfUrl);
      } else {
        throw new Error('Invalid response or empty PDF data');
      }

      setIsLoading(false); // Set loading state to false after processing
    } catch (error) {
      setIsLoading(false);
      setError(true);
        // Fallback if the response structure is different
        setErrorMessage("Please verify the screening, labs, tests (Framingham), and report tabs before attempting to create the report again.");    
      // Reset error state after a timeout to hide the error message
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };

  // Function to handle copying the UUID of the currently clicked item
  const handleCopyData = (uuid: string) => {
    // Copy the UUID to the clipboard
    const el = document.createElement('textarea');
    el.value = uuid;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Set the copy success for the specific UUID to true
    setCopySuccesses((prevCopySuccesses) => ({
      ...prevCopySuccesses,
      [uuid]: true,
    }));

    // Reset copySuccess after a short delay
    setTimeout(() => {
      setCopySuccesses((prevCopySuccesses) => ({
        ...prevCopySuccesses,
        [uuid]: false,
      }));
    }, 3000); // Reset copySuccess after 3 seconds
  };

  const loadMore = () => {
    setInitialCardCount(initialCardCount + 5);
  };

  const navigate = useNavigate(); // Initialize the useHistory hook

  const handleEdit = (event: React.MouseEvent, uuid: string) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    event.stopPropagation(); // Stop event propagation
    navigate(`/patient/screening/${uuid}`);
  };

  return (
    // <!-- Features -->
    <div className="rounded-lg">
      <LoadingPopup isLoading={isLoading} />
      {/* <!-- Grid --> */}
      <h3 className="text-xl font-semibold text-black dark:text-white px-2 py-3">
        Visit History
      </h3>
      {error && (
        <InfoPopup
          color="red"
          boldText="Insufficient data to generate the report:"
          text={errorMessage}
          icon={AlertTriangle}
        />
      )}
      {/* <!-- Tab Navs --> */}
      <nav className="grid" aria-label="Tabs" role="tablist">
        {visitData.slice(0, initialCardCount).map((item) => (
          <div
            className={`my-2 rounded-lg border dark:bg-gray-800 dark:border-gray-700 dark:hover-bg-gray-700 ${
              item.uuid === urlUUID ? 'bg-gray-100' : ''
            }`}
            key={item.id}
          >
            {' '}
            <a
              href={`${REACT_APP_FRONTEND_BASE_URL}/patient/${item.uuid}`}
              className="block"
            >
              <div className="cursor-pointer py-4">
                <div className="px-6 flex items-center">
                  <h5 className="text-sm font-bold text-left text-gray-900 dark:text-white">
                    Visit Date:
                  </h5>
                  <p className="text-gray-700 text-xs pl-2 dark:text-white">
                    {convertUTCToSGTimestamp(item.visitDate)}
                  </p>
                  <div
                    className="pl-2"
                    onContextMenu={(e) => handleRightClick(e, item)} // Add right-click handler here
                  >
                    <span
                      className={`inline-flex items-center rounded-lg px-3 py-1 text-xs font-medium mr-2 mb-2 ${
                        item.healthScreeningPackageName ===
                        'No package selected'
                          ? 'text-black bg-gray-200'
                          : 'bg-primaryColor-50 text-primaryColor-500'
                      }`}
                    >
                      {item.healthScreeningPackageName}
                      {/* Conditionally disable or render chevron */}
                      {item.uuid === urlUUID && (
                        <ChevronDown className="w-4 h-4 ml-1" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="px-6 flex justify-between">
                  <div className="flex items-center">
                    <h5 className="inline-block text-sm font-bold text-left text-gray-900 dark:text-white">
                      Visit ID:
                    </h5>
                    <p className="inline-block text-gray-700 text-xs pl-2 dark:text-white">
                      {item.uuid}
                    </p>
                  </div>
                  <div className="flex">
                    <ToolbarButton
                      text="Copy Report URL"
                      icon={
                        !copyURLSuccesses[item.uuid] ? (
                          <Link2 className="w-4 h-4" />
                        ) : (
                          <Check className="w-4 h-4 text-primaryColor-500 text-medium" />
                        )
                      }
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior of the anchor tag
                        handleCopyReportURL(e, item.uuid);
                      }}
                    />

                    <ToolbarButton
                      text="Print Report"
                      icon={<Printer className="w-4 h-4" />}
                      onClick={(e) => handlePrintPdf(e, item.uuid)}
                    />
                    <ToolbarButton
                      text="Copy Visit ID"
                      icon={
                        !copySuccesses[item.uuid] ? (
                          <Copy className="w-4 h-4" />
                        ) : (
                          <Check className="w-4 h-4 text-primaryColor-500 text-medium" />
                        )
                      }
                      onClick={(e) => {
                        e.preventDefault(); // Prevent the default behavior of the anchor tag
                        handleCopyData(item.uuid);
                      }}
                    />
                    <ToolbarButton
                      text="Edit Records"
                      icon={<Pencil className="w-4 h-4" />}
                      onClick={(e) => handleEdit(e, item.uuid)}
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </nav>
      {/* <!-- End Tab Navs --> */}
      {initialCardCount < visitData.length && (
        <div className="flex justify-center my-2">
          <button
            onClick={loadMore}
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              Load more
            </span>
          </button>
        </div>
      )}

      {contextMenu && (
        <Menu
          x={contextMenu.x}
          y={contextMenu.y}
          options={contextMenu.options}
          onClose={() => setContextMenu(null)}
        />
      )}
    </div>
  );
};

export default CardVisitList;
